<template>
  <div class="container-fluid">
    <b-row>
      <b-col cols="12">
        <h2>{{ $t('views.client.import.steps.step-0.general.title') }}</h2>
        <div class="d-flex justify-content-between align-items-center">
          <h3>{{ $t('views.client.import.steps.step-0.general.subtitle') }}</h3>
          <button class="download">
            {{ $t('views.client.import.steps.step-0.general.download-sample') }}
            <i class="fa fa-download f-18"></i>
          </button>
        </div>
        <button
          class="upload"
          :class="{ dragging }"
          ref="fileform"
          @click="upload"
        >
          <i class="fa fa-cloud-upload f-18"></i>
          <template v-if="uploading">
            {{ $t('views.client.import.steps.step-0.general.in-progress') }}
          </template>
          <template v-else-if="dragging">
            {{ $t('views.client.import.steps.step-0.general.drop') }}
          </template>
          <template v-else>
            {{ $t('views.client.import.steps.step-0.general.subtitle') }}
          </template>
        </button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  props: {
    uploading: {
      type: Boolean
    },
  },
  data() {
    return {
      dragging: false,
      dragAndDropCapable: false
    };
  },
  methods: {
    upload() {
      if (!this.uploading) {
        this.$upload(this.uploaded, {
          entry: 'import',
          url: null,
          target: '',
          accept: '.csv'
        });
      }
    },
    uploaded(file) {
      if (file.type !== 'text/csv') {
        this.$flash(null, this.$t('views.client.import.steps.step-0.general.wrong-format'));
        return;
      }
      this.$emit('on:new-file', file)
    },
    determineDragAndDropCapable() {
      let div = document.createElement('div');
      return ( 'draggable' in div )
              || ( 'ondragstart' in div && 'ondrop' in div );
    },
  },
  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();

    if(this.dragAndDropCapable){
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(evt => {
        this.$refs.fileform.addEventListener(evt, e => {
          e.preventDefault();
          e.stopPropagation();
        }, false);
      });

      this.$refs.fileform.addEventListener('dragenter', e => {
        this.dragging = true
      });
      this.$refs.fileform.addEventListener('dragleave', e => {
        this.dragging = false
      });

      this.$refs.fileform.addEventListener('drop', e => {
        this.dragging = false
        if (e.dataTransfer.files.length > 1) {
          this.$flash(null, this.$t('views.client.import.steps.step-0.general.one-file'));
        } else {
          this.uploaded(e.dataTransfer.files[0])
        }
      });
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@lazy/client/import/_client-step0.scss";
</style>
